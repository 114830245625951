<template>
  <v-card class="mb-12" height="auto">
    <v-app id="inspire">
      <!-- <div class="ml-8">
        <h4 class="primary--text">Export Report as Excel</h4>
        <v-btn class="primary" :href="basePath + buildingExcelExport">Export all Building Data in Excel</v-btn>
      </div> -->
      <div>
        <v-col cols="6">
          <v-card class="mx-auto" outlined>
            <v-card-title class="text-capitalize">export data in excel</v-card-title>
            <v-card-text>
              <v-row class="ma-0 pa-0">
                <!-- <v-col class="ma-0 pa-0"><v-date-picker v-model="dateRange" range></v-date-picker></v-col>
                <v-col class="ma-0 pa-0 font-weight-bold">{{ dateRangeText }}</v-col> -->
                <v-col cols="4">
                  <NepaliDatePicker v-model="dateFilter.start_date" label="निवेदन मिति(वि.स.)" />
                </v-col>
                <v-col cols="4">
                  <NepaliDatePicker v-model="dateFilter.end_date" label="निवेदन मिति(वि.स.)" />
                </v-col>
                <v-col cols="8" class="text-h5" v-if="dateFilter.start_date || dateFilter.end_date">
                  {{ dateFilter.start_date }} देखी {{ dateFilter.end_date }} सम्म
                </v-col>
              </v-row>

              <v-btn class="cyan darken-3 white--text" @click="exportToExcel">Export</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
      <v-row>
        <v-col cols="6">
          <GChart class="piechart" type="PieChart" @ready="onChartReady" />
        </v-col>
        <v-col cols="6">
          <GChart class="piechart" type="PieChart" @ready="buildingCategory" />
        </v-col>
        <v-col cols="6">
          <GChart class="piechart" type="PieChart" @ready="buildingStorey" />
        </v-col>
        <v-col cols="6">
          <GChart class="columnchart" type="ColumnChart" @ready="buildingFootprintArea" />
        </v-col>
        <v-col cols="6">
          <GChart class="piechart" type="PieChart" @ready="buildingStructure" />
        </v-col>
        <v-col cols="6">
          <GChart class="piechart" type="PieChart" @ready="buildingUse" />
        </v-col>
      </v-row>
      <v-row class="mx-10">
        <v-col cols="12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> Building stages </v-toolbar-title>
          </v-toolbar>
          <a href=""></a>
          <v-simple-table class="sober">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable" v-for="p in applicationHeader" :key="p">
                    {{ p }}
                  </th>
                  <th class="text-left registrationtable">Issued</th>
                </tr>
              </thead>
              <tbody>
                <tr class="registrationtable" v-for="applicationStatusList in applicationStatus" :key="applicationStatusList.id">
                  <td class="registrationtable">{{ applicationStatusList["Level"] }}</td>
                  <td class="registrationtable">
                    <p v-html="applicationStatusList['Registration Desk']"></p>
                  </td>
                  <td>{{ applicationStatusList["Technical Desk"] }}</td>
                  <td class="registrationtable">{{ applicationStatusList["Executive Desk"] }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <table hidden>
            <thead>
              <tr class="registrationtable">
                <th class="text-left registrationtable">S.N</th>
                <th class="text-left registrationtable">Construction Typology</th>
                <th class="text-left registrationtable">Revenue</th>
                <th class="text-left registrationtable">Revenue(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="registrationtable">
                <td class="registrationtable">1</td>
                <td class="registrationtable">Rcc</td>
                <td></td>
                <td class="registrationtable"></td>
              </tr>
              <tr class="registrationtable">
                <td class="registrationtable">2</td>
                <td class="registrationtable">Masonry</td>
                <td>Compliance</td>
                <td class="registrationtable"></td>
              </tr>
              <tr class="registrationtable">
                <td class="registrationtable">3</td>
                <td class="registrationtable">Total</td>
                <td>Compliance</td>
                <td class="registrationtable"></td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" class="ma-auto">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> Revenue </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="border">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable" v-for="p in revenueHeader" :key="p">
                    <span class="font-18">{{ p }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="registrationtable" v-for="revenueStatusList in revenueStatus" :key="revenueStatusList.id">
                  <td class="registrationtable">
                    <span
                      class="blue--text text--darken-3 font-18"
                      v-bind:class="{
                        'font-weight-bold': revenueStatusList['Building Use'] == 'Total',
                      }"
                      >{{ revenueStatusList["Building Use"] }}</span
                    >
                  </td>
                  <td class="registrationtable">
                    <span
                      class="blue--text text--darken-3 font-18"
                      v-bind:class="{
                        'font-weight-bold': revenueStatusList['Building Use'] == 'Total',
                      }"
                      >{{ Number.parseFloat(revenueStatusList["Revenue"]).toFixed(2) }}</span
                    >
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-app>
  </v-card>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";

export default {
  name: "Report",
  components: {
    NepaliDatePicker,
  },
  data() {
    return {
      i: 0,
      basePath: process.env.VUE_APP_BASE_URL,
      buildingExcelExport: "api/export-all",
      buildingStage: [],
      applicationStatus: [],
      applicationHeader: [],
      revenueStatus: [],
      revenueHeader: [],
      consultancy: [],
      dateFilter: {
        start_date: "",
        end_date: "",
      },
    };
  },
  computed: {
    dateRangeText() {
      return "From " + this.dateRange.join(" to ");
    },
  },
  mounted() {
    const url = `api/report/application-status`;
    VueAxios.get(url)
      .then((response) => {
        this.applicationStatus = response.data.data;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const revenueurl = `api/report/revenue`;
    VueAxios.get(revenueurl)
      .then((response) => {
        this.revenueStatus = response.data.data;
        // console.log(`revenue status: ${this.revenueStatus}`);
        this.revenueHeader = Object.keys(this.revenueStatus[0]);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const consultancyUrl = `api/report/consultancy`;
    VueAxios.get(consultancyUrl)
      .then((response) => {
        this.consultancy = response.data.data;
        // console.log(this.revenueStatus);
        // this.masonHeader = Object.keys(this.revenueStatus[0]);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    onChartReady(chart, google) {
      VueAxios.get(`api/report/construction-type`).then((response) => {
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        // console.log(response.data.data);
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingCategory(chart, google) {
      VueAxios.get(`api/report/building-category`).then((response) => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingStorey(chart, google) {
      VueAxios.get(`api/report/building-storey`).then((response) => {
        this.buildingStoreyTitle = response.data.Title;
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingFootprintArea(chart, google) {
      VueAxios.get(`api/report/building-area`).then((response) => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingStructure(chart, google) {
      VueAxios.get(`api/report/building-structure`).then((response) => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingUse(chart, google) {
      VueAxios.get(`api/report/building-use`).then((response) => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    exportToExcel() {
      if (!this.dateFilter.start_date || !this.dateFilter.end_date) {
        console.log("Start or End date can't be empty");
      } else {
        const exportExcelUrl = `api/export-excel/all-building`;
        this.$axios
          .post(exportExcelUrl, this.dateFilter, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            const filename = "रिपोर्ट-" + this.dateFilter.start_date + "देखि" + this.dateFilter.end_date + "सम्म.xlsx";
            console.log("filename " + filename);
            a.setAttribute("download", filename);
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch();
      }
    },
  },
};
</script>

<style scoped>
.piechart {
  width: 900px;
  height: 500px;
}
.font-18 {
  font-size: 18px;
}
</style>
