<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" class="mx-4" v-model="valid">
          <div v-for="(n, index) in newLandinfoarea.landinfoarea" :key="n.id">
            <v-toolbar v-if="index > 0" color="primary" dark flat height="50px" class="pa-0">
              <v-spacer></v-spacer>
              <v-btn icon dark @click="deleteForm(index, n.id)"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-row>
              <v-col cols="5">
                <label> भू-उपयोग क्षेत्र<span>*</span></label>
              </v-col>
              <v-col cols="7">
                <v-select
                  v-model="n.land_use_area"
                  :items="landUseArea"
                  item-value="value"
                  class="custom-text-field"
                  item-text="value"
                  label="भू-उपयोग क्षेत्र"
                  :rules="[required('Land Use Type')]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">
                <label>जग्गा कित्ता नं<span>*</span></label>
              </v-col>
              <v-col md="7">
                <v-text-field
                  v-model="n.land_plot_no"
                  :rules="[required('Land Plot No.')]"
                  label="जग्गा कित्ता नं"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-weight-bold table-title text-bottom">
                क्षेत्रफल
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-checkbox v-model="ropaniDivChecked" label="आनामा" hide-details></v-checkbox>
              </v-col>
              <v-col cols="10" class="ropani-div" v-if="ropaniDivChecked">
                <v-row>
                  <v-col cols="1" class="text-right text-bottom">
                    रोपनी
                  </v-col>
                  <v-col md="2">
                    <v-text-field v-model="n.area_ropani" label="रोपनी"></v-text-field>
                  </v-col>
                  <v-col class="text-right text-bottom">
                    आना
                  </v-col>
                  <v-col md="2">
                    <v-text-field v-model="n.area_aana" label="आना"></v-text-field>
                  </v-col>
                  <v-col class="text-right text-bottom">
                    पैसा
                  </v-col>
                  <v-col md="2">
                    <v-text-field v-model="n.area_paisa" label="पैसा"></v-text-field>
                  </v-col>
                  <v-col class="text-right text-bottom">
                    दाम
                  </v-col>
                  <v-col md="2">
                    <v-text-field v-model="n.area_daam" label="दाम"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-checkbox v-model="bighaDivChecked" label="कठ्ठामा" hide-details></v-checkbox>
              </v-col>
              <v-col cols="10" class="bigha-div" v-if="bighaDivChecked">
                <v-row>
                  <v-col cols="1" class="text-right text-bottom">
                    बिघा
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="n.area_bigha" label="बिघा"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="text-right text-bottom">
                    कठ्ठा
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="n.area_kattha" label="कठ्ठा"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="text-right text-bottom">
                    धुर
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="n.area_dhur" label="धुर"></v-text-field>
                  </v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
            <v-row>
              <v-col cols="2" class=" text-bottom">
                Area (sqm)
              </v-col>
              <v-col cols="2">
                <v-text-field
                  @focus="sqmflag = true"
                  @blur="sqmflag = false"
                  v-model="n.area_sqm"
                  label="Area (sqm)"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class=" text-bottom">
                Area (sqft)
              </v-col>
              <v-col cols="2">
                <v-text-field
                  @focus="sqftflag = true"
                  @blur="sqftflag = false"
                  v-model="n.area_sqft"
                  label="Area (sqft)"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <v-row class="white">
          <v-col cols="auto">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="addForm"
              class="primary"
              :disabled="!valid"
              >+ Add Another</v-btn
            >
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isPostAuthorized: Boolean
  },
  data() {
    return {
      required(propertyType) {
        return v => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          v => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          v => /^[\W]+$/.test(v) || `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          v => !!v || `${propertyType} is reqiuired`,
          v => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      requiredPhone(propertyType) {
        return [
          v => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          v =>
            /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) || `${propertyType} is not valid phone number`
        ];
      },
      valid: false,
      ropaniDivChecked: true,
      bighaDivChecked: false,
      landUseArea: [
        { id: 1, value: "आवासिय" },
        { id: 2, value: "व्यवसायिक" },
        { id: 3, value: "स्वास्थ्य" },
        { id: 4, value: "शिक्षा" },
        { id: 5, value: "सरकारी र अर्धसरकारी" },
        { id: 6, value: "जमघट" },
        { id: 7, value: "औद्योगिक" },
        { id: 8, value: "होटल सेवा र सुविधा वितरण" },
        { id: 9, value: "अपार्टमेन्ट" },
        { id: 10, value: "संघसंस्था" },
        { id: 11, value: "अन्य" }
      ],
      landinfoarea: [],
      newLandinfoarea: {
        landinfoarea: []
        // id: 0,
        // reg_id: this.registrationId,
        // land_plot_no: "",
        // area_ropani: "",
        // area_aana: "",
        // area_paisa: "",
        // area_daam: "",
        // area_bigha: "",
        // area_kattha: "",
        // area_dhur: "",
        // land_use_area: "",
        // area_sqm: "",
        // area_sqft: ""
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      sqmflag: false,
      sqftflag: false
    };
  },
  created() {
    this.areaSqFt();
    this.areaSqM();
    this.bighaDivCheckedFinal();
    this.ropaniDivCheckedFinal();
    this.sqmToSqft();
    this.sqftToSqm();
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}/land-info-area`;

    this.$axios
      .get(url, {
        timeout: 100000
      })
      .then(response => {
        // debugger;
        this.newLandinfoarea.landinfoarea = [];
        this.newLandinfoarea.landinfoarea = response.data.data;
        // this.edit(this.landinfoarea[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newLandinfoarea.clear();
        if (this.newLandinfoarea.landinfoarea.length === 0) {
          this.addForm();
        }
      })
      .catch(error => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
  },
  computed: {
    areaSqFt: function() {
      let areaFt = 0;
      if (
        !!this.newLandinfoarea.area_ropani ||
        !!this.newLandinfoarea.area_aana ||
        !!this.newLandinfoarea.area_aana ||
        !!this.newLandinfoarea.area_daam
      ) {
        areaFt =
          parseFloat(!!this.newLandinfoarea.area_ropani ? this.newLandinfoarea.area_ropani : 0) *
            5476.0 +
          parseFloat(!!this.newLandinfoarea.area_aana ? this.newLandinfoarea.area_aana : 0) *
            342.25 +
          parseFloat(!!this.newLandinfoarea.area_paisa ? this.newLandinfoarea.area_paisa : 0) *
            85.5625 +
          parseFloat(!!this.newLandinfoarea.area_daam ? this.newLandinfoarea.area_daam : 0) *
            21.390625;
      } else if (
        !!this.newLandinfoarea.area_bigha ||
        !!this.newLandinfoarea.area_kattha ||
        !!this.newLandinfoarea.area_dhur
      ) {
        areaFt =
          parseFloat(!!this.newLandinfoarea.area_bigha ? this.newLandinfoarea.area_bigha : 0) *
            72900 +
          parseFloat(!!this.newLandinfoarea.area_kattha ? this.newLandinfoarea.area_kattha : 0) *
            3645 +
          parseFloat(!!this.newLandinfoarea.area_dhur ? this.newLandinfoarea.area_dhur : 0) *
            182.25;
      }

      return areaFt;
    },
    areaSqM: function() {
      let areaM = 0;
      if (!!this.newLandinfoarea.area_ropani) {
        areaM =
          parseFloat(!!this.newLandinfoarea.area_ropani ? this.newLandinfoarea.area_ropani : 0) *
            508.73704704 +
          parseFloat(!!this.newLandinfoarea.area_aana ? this.newLandinfoarea.area_aana : 0) *
            31.79606544 +
          parseFloat(!!this.newLandinfoarea.area_paisa ? this.newLandinfoarea.area_paisa : 0) *
            7.94901636 +
          parseFloat(!!this.newLandinfoarea.area_daam ? this.newLandinfoarea.area_daam : 0) *
            1.98725409;
      } else if (!!this.newLandinfoarea.area_bigha) {
        areaM =
          parseFloat(!!this.newLandinfoarea.area_bigha ? this.newLandinfoarea.area_bigha : 0) *
            6772.41 +
          parseFloat(!!this.newLandinfoarea.area_kattha ? this.newLandinfoarea.area_kattha : 0) *
            338.6319 +
          parseFloat(!!this.newLandinfoarea.area_dhur ? this.newLandinfoarea.area_dhur : 0) * 16.93;
      }

      console.log("Area in Meter");
      console.log(`sqftflag: ${this.sqftflag}`);
      console.log(`sqmflag: ${this.sqmflag}`);
      console.log(areaM);
      return areaM;
    },
    bighaDivCheckedFinal: function() {
      if (this.ropaniDivChecked == true) {
        return false;
      } else {
        return this.bighaDivChecked;
      }
    },
    ropaniDivCheckedFinal: function() {
      if (this.bighaDivChecked == true) {
        return false;
      } else {
        return this.ropaniDivChecked;
      }
    },
    sqmToSqft: function() {
      // if ((sqftflag == false || sqftflag == true )&&sqmflag==true) {
      return parseFloat(this.newLandinfoarea.area_sqm * 10.7639);
      // }
      // else{
      // return null;
      // }
    },
    sqftToSqm: function() {
      //   if (sqmflag == false && sqftflag == true) {
      return parseFloat(this.newLandinfoarea.area_sqft / 10.7639);
      //   } else {
      //     return null;
      //   }
    }
  },
  watch: {
    areaSqFt() {
      this.newLandinfoarea.area_sqft = this.areaSqFt.toFixed(2);
    },
    areaSqM() {
      this.newLandinfoarea.area_sqm = this.areaSqM.toFixed(2);
    },
    bighaDivCheckedFinal() {
      this.bighaDivChecked = this.bighaDivCheckedFinal;
    },
    ropaniDivCheckedFinal() {
      // this.bighaDivChecked = !this.ropaniDivChecked;
      this.ropaniDivChecked = this.ropaniDivCheckedFinal;
    },
    sqmToSqft() {
      // if ((sqftflag == false || sqftflag == true) && sqmflag == true) {
      if (!this.sqftflag) {
        this.newLandinfoarea.area_sqft = parseFloat(this.sqmToSqft).toFixed(2);
      }
    },
    sqftToSqm() {
      if (!this.sqmflag) {
        this.newLandinfoarea.area_sqm = parseFloat(this.sqftToSqm).toFixed(3);
      }
    }
  },
  methods: {
    save() {
      // debugger;
      this.isLoading = true;
      if (
        this.newLandinfoarea.landinfoarea[0].id === "null" ||
        this.newLandinfoarea.landinfoarea[0].id === 0 ||
        this.newLandinfoarea.landinfoarea[0].id === undefined
      ) {
        const saveUrl = `/api/registration/${this.$route.params.id}/land-info-area/list`;
        this.$axios
          .post(saveUrl, this.newLandinfoarea)
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.landinfoarea.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Land Area Data Successful.";
            }
          })
          .catch(error => {
            // alert(2);
            this.isLoading = false;
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.id}/land-info-area/list`;
        this.$axios
          .put(eurl, this.newLandinfoarea)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              // const i = this.landinfoarea.map(item => item.id).indexOf(this.newLandinfoarea.id); // find index of your object
              // this.landinfoarea[i].land_plot_no = this.newLandinfoarea.land_plot_no;
              // this.landinfoarea[i].map_sheet_no = this.newLandinfoarea.map_sheet_no;
              // this.landinfoarea[i].actual_setback = this.newLandinfoarea.actual_setback;
              // this.landinfoarea[i].designer_name = this.newLandinfoarea.designer_name;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Land Area Data updated successfully";
              // this.newLandinfoarea.id = 0;
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    // edit(id) {
    //   //    const url = `api/registration/${this.$route.params.id}/designer/${this.$route.params.id}`;
    //   const url = `api/registration/${this.$route.params.id}/land-info-area/list`;
    //   this.$axios.get(url).then(response => {
    //     // debugger;
    //     this.newLandinfoarea = response.data.data;
    //     console.log(response.data.data);
    //   });
    // },
    // deleterecord(id) {
    //   const deleteurl = `api/registration/${this.$route.params.id}/land-info-area/list`;
    //   this.$axios
    //     .delete(deleteurl, {
    //       timeout: 100000
    //     })
    //     .then(response => {
    //       if (response.status === 200 || response.status === 201) {
    //         this.snackbar.show = true;
    //         this.successfulMessage = "Data deleted successfully";
    //         const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
    //         this.landinfoarea.splice(i, 1);
    //       }
    //       console.log(response);
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    addForm() {
      this.newLandinfoarea.landinfoarea.push({
        id: 0,
        reg_id: this.registrationId,
        land_plot_no: "",
        area_ropani: "",
        area_aana: "",
        area_paisa: "",
        area_daam: "",
        area_bigha: "",
        area_kattha: "",
        area_dhur: "",
        land_use_area: "",
        area_sqm: "",
        area_sqft: ""
      });
    },
    deleteForm(index, id) {
      this.newLandinfoarea.landinfoarea.splice(index, 1);
      const deleteurl = `api/registration/${this.$route.params.id}/land-info-area/${id}`;
      this.$axios
        .delete(deleteurl)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            // const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
            // this.landinfoarea.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
label span {
  color: red;
}
/* .height-800 {
  height: 300px;
  overflow-y: auto;
} */
.text-bottom {
  /* padding-top: 1.5rem; */
  margin: auto 0px;
}

.col {
  padding: 0 15px;
}

.row:nth-child(even) {
  /* background-clip: content-box; */
  background-color: #eceff1;
}
</style>
