<template>
  <div>
    <v-form ref="form" v-model="valid" style="margin-bottom: 1rem">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> Consultancy Member List</v-toolbar-title>
      </v-toolbar>
      <br />

      <v-simple-table class="sober" width="100%">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable">Full Name</th>
              <th class="text-left registrationtable">Email</th>
              <th class="text-left registrationtable">Phone</th>
              <th class="text-left registrationtable">Designer Type</th>
              <th class="text-left registrationtable">NEC Number</th>
              <th class="text-left registrationtable" v-if="isAuthorized">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="(item, i) in consultancymemberlist" :key="i.id">
              <td>
                {{ item.first_name + " " + item.last_name }}
              </td>
              <td>{{ item.member_email }}</td>
              <td>{{ item.member_phone_no }}</td>
              <td v-if="isDesignerType">
                {{ item.designer_type_id == 1 ? "Structural" : "Architectural" }}
              </td>
              <td>{{ item.nec_no }}</td>
              <td class="registrationtable">
                <v-btn
                  class="primary"
                  small
                  v-on:click="editConsultancyMember(item.id)"
                  v-if="isAuthorized"
                  >Edit</v-btn
                >

                <custom-dialog
                  click-type="delete"
                  :click-dialog="deleteForm"
                  v-if="isAuthorized"
                  :regId="item.id"
                  >Delete</custom-dialog
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />

      <v-row>
        <!-- <div v-for="(n, index) in newconsultancymember.consultancymemberinfo" :key="n.id">
          <v-col>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="n.first_name" label="First Name" outlined></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="n.middle_name" label="Middle Name" outlined></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="n.last_name" label="Last Name" outlined></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="n.designer_type_id"
                  label="Select Designer"
                  :items="designerTypes"
                  item-value="id"
                  item-text="name_en"
                  :key="id"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="n.member_phone_no"
                  label="Phone Number"
                  type="number"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="n.member_email"
                  label="Email"
                  type="text"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="n.nec_no"
                  label="NEC Number"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  v-model="n.nec_certificate"
                  label="NEC File Certificate"
                  outlined
                  clearable
                ></v-file-input>
                <div style="height: 140px" v-if="n.nec_photo != null">
                  <inline-doc :src="basePath + n.nec_photo"></inline-doc>
                </div>
              </v-col>
              <v-col cols="2">
                <v-btn
                  v-if="index > 0"
                  color="error"
                  fab
                  small
                  dark
                  @click="deleteForm(index, n.id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </div> -->

        <div v-if="showForm">
          <v-col>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.first_name"
                  label="First Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.middle_name"
                  label="Middle Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.last_name"
                  label="Last Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="consultancymember.designer_type_id"
                  label="Select Designer"
                  :items="designerTypes"
                  item-value="id"
                  item-text="name_en"
                  :key="id"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.member_phone_no"
                  label="Phone Number"
                  type="number"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.member_email"
                  label="Email"
                  type="text"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="consultancymember.nec_no"
                  label="NEC Number"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-file-input
                  v-model="consultancymember.nec_certificate"
                  label="NEC File Certificate"
                  outlined
                  clearable
                ></v-file-input>
              </v-col>

              <!-- <v-col cols="4">
                <div style="height: 140px" v-if="nec_photo != null">
                  <inline-doc :src="basePath + nec_photo"></inline-doc>
                </div>
              </v-col> -->
            </v-row>
          </v-col>
        </div>
      </v-row>
      <v-btn
        primary
        @click="saveConsultancyMember(consultancymember.id)"
        class="ma-3"
        large
        color="primary"
        v-if="saveMemberBtn && isAuthorized"
        >Save</v-btn
      >
      <v-btn
        primary
        @click="updateConsultancyMember(consultancymember.id)"
        class="ma-3"
        large
        color="primary"
        v-if="updateMemberBtn"
        >Update</v-btn
      >
      <v-btn class="mx-9" fab dark small color="primary" v-on:click="addForm" v-if="isAuthorized">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    conId: Number,
  },
  data: () => ({
    showForm: false,
    saveMemberBtn: true,
    updateMemberBtn: false,
    consultancymemberlist: [
      {
        first_name: "",
        last_name: "",
        member_phone_no: "",
        member_email: "",
        designer_type_id: "",
        nec_no: "",
        nec_certificate: "",
      },
    ],
    isAuthorized: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    valid: false,
    valid: false,
    required(propertyType) {
      return (v) => !!v || `${propertyType} is required`;
    },
    requiredFormat(propertyType, regexexp) {
      var regex = new RegExp(regexexp, "g");
      return [
        (v) => !!v || `${propertyType} is reqiuired`,
        (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
        // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
      ];
    },
    requiredPhone(propertyType) {
      return [
        (v) => !!v || `${propertyType} is required`,
        // v => /^\w$/ || 'Please write in english format',
        (v) =>
          /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) || `${propertyType} is not valid phone number`,
      ];
    },
    consultancy: [],
    nec_photo: null,
    successfulMessage: "",
    snackbar: {
      show: false,
      color: "success",
    },

    newconsultancymember: {
      consultancymemberinfo: [],
    },

    designerTypes: [],
    consultancymember: {
      first_name: "",
      middle_name: "",
      last_name: "",
      designer_type_id: "",
      member_phone_no: "",
      member_email: "",
      nec_no: "",
      nec_certificate: "",
    },
    formData: new FormData(),
    basePath: process.env.VUE_APP_BASE_URL,
    fileSizeValidate() {
      return [
        (v) =>
          !v || v.size < this.fileSize * 1000000 || `File should be less than ${this.fileSize} MB`,
      ];
    },
    isDesignerType: '',
  }),
  created() {
    this.getConsultancyData();
    this.getMemberData();
    this.addForm();
    this.editConsultancyMember();
    this.updateConsultancyMember();
  },
  mounted() {
    this.getConsultancyMemberList();
    this.consultancyMemberAuthorized();
    this.getDesignerTypes();
  },
  methods: {
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      this.buildFormData(this.formData, data);
      return this.formData;
    },

    getConsultancyData() {
      const conDataUrl = `api/consultancy-profile`;
      this.$axios
        .get(conDataUrl)
        .then((response) => {
          if (response.status === 200) {
            this.consultancy = response.data.data[0];
          }
        })
        .catch((error) => {
          console.log(`consultancy api error:${error}`);
        });
    },
    // getMemberData() {
    //   const url = `api/consultancy-member`;
    //   this.$axios
    //     .get(url)
    //     .then((response) => {
    //       this.newconsultancymember.consultancymemberinfo = [];
    //       this.newconsultancymember.consultancymemberinfo = response.data.data;
    //       this.newconsultancymember.consultancymemberinfo.forEach((i) => {
    //         i.nec_photo = i.nec_certificate;
    //       });
    //       this.isLoading = false;
    //       if (this.newconsultancymember.consultancymemberinfo.length === 0) {
    //         this.addForm();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(`api error:${error}`);
    //       this.isLoading = false;
    //     });
    // },

    updateConsultancyProfile(id) {
      const url = `api/consultancy-profile/${id}`;
      this.$axios.put(url, this.consultancy).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.consultancy = response.data.data[0];
          this.snackbar.show = true;
          this.successfulMessage = "Profile Updated Successfuly.";
        }
      });
    },

    addForm() {
      // this.newconsultancymember.consultancymemberinfo.push({
      //   id: null,
      //   name: "",
      //   designation: "",
      //   phone_no: "",
      //   nec_no: "",
      // });

      this.showForm = true;
    },

    // deleteForm(index, id) {
    //   this.newconsultancymember.consultancymemberinfo.splice(index, 1);
    //   const deleteurl = `api/consultancy-member/${id}/`;
    //   this.$axios
    //     .delete(deleteurl)
    //     .then((response) => {
    //       if (response.status === 200 || response.status === 201) {
    //         this.snackbar.show = true;
    //         this.successfulMessage = "Data deleted successfully";
    //       }
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    saveMember(bps_id) {
      this.isLoading = true;
      if (
        this.newconsultancymember.consultancymemberinfo[0].id === null ||
        this.newconsultancymember.consultancymemberinfo[0].id === 0 ||
        this.newconsultancymember.consultancymemberinfo[0].id === undefined
      ) {
        const saveUrl = `/api/consultancy-member/${bps_id}/consultancy-member-list`;
        const self = this;
        this.formData = this.jsonToFormData(this.newconsultancymember);
        this.$axios
          .post(saveUrl, self.formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Consultancy Member Added Successfully.";
            }
          })
          .catch((error) => {
            console.log(`api error:${error}`);
            this.isLoading = false;
          });
      } else {
        const url = `/api/consultancy-member/${bps_id}/consultancy-member-list`;
        const self = this;
        this.formData = this.jsonToFormData(this.newconsultancymember);
        self.formData.append("_method", "PUT");
        this.$axios
          .post(url, self.formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Consultancy Member Updated Successfully";
              this.getMemberData();
            }
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },

    getDesignerTypes() {
      const url = `api/designer-types`;
      this.$axios
        .get(url)
        .then((response) => {
          this.designerTypes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getConsultancyMemberList() {
      const conDataUrl = `api/consultancy-profile`;
      this.$axios
        .get(conDataUrl)
        .then((response) => {
          if (response.status === 200) {
            let tempId = response.data.data["id"];
            const ConsultancyMemberListUrl = `api/consultancy-member/${tempId}/consultancy-member-list`;
            // this.isLoading = true;
            if (response.status === 200) {
              this.$axios
                .get(ConsultancyMemberListUrl)
                .then((response) => {
                  // alert(response.data.data["bps_user_id"]);
                  this.consultancymemberlist = response.data.data;
                  this.isDesignerType = response.data.data.designer_type_id
                })
                .catch((error) => {
                  console.log(`api error:${error}`);
                  this.isLoading = false;
                  reject(error);
                });
            }
          }
        })
        .catch((error) => {
          console.log(`consultancy api error:${error}`);
        });
    },

    editConsultancyMember(id) {
      const consultancy_member_id = id;
      this.showForm = true;
      this.saveMemberBtn = false;
      this.updateMemberBtn = true;
      const url = `api/consultancy-member/${consultancy_member_id}/consultancy-member`;
      this.$axios
        .get(url)
        .then((response) => {
          this.consultancymember = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false;
        });
    },

    updateConsultancyMember(id) {
      const consultancy_member_id = id;
      const url = `api/consultancy-member/${consultancy_member_id}/consultancy-member`;
      const self = this;
      this.formData = this.jsonToFormData(this.consultancymember);
      self.formData.append("_method", "PUT");
      this.$axios
        .post(url, self.formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Consultancy Member Updated Successfully";
            this.getMemberData();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    deleteForm(id) {
      alert(this.id);
      const deleteurl = `api/consultancy-member/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    consultancyMemberAuthorized() {
      this.isDataLoaded = false;
      const url = `api/consultancy-member/is-authorized`;
      this.$axios
        .get(url)
        .then((response) => {
          this.isAuthorized = response.data.data;
        })
        .catch((error) => console.log(`api error:${error}`));
    },
  },
};
</script>
