<template>
  <div>
    <Navbar />

    <b-container class="my-4">
      <v-form v-model="valid">
        <h3 class="text-center">General Information</h3>
        <v-subheader>[<strong>Note</strong>: Basic Information of consultancy]</v-subheader>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="consultancy.name"
              label="Company Name"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="consultancy.name_np"
              :rules="requiredFormat('परामर्श नाम', '^[\u0900-\u0966 \u0970-\u097F \.]+$')"
              label="Company Name in Nepali"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="consultancy.email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider inset></v-divider>

        <h4>Address Information</h4>
        <v-subheader>[<strong>Note</strong>: Address Information of consultancy]</v-subheader>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="consultancy.district_id"
              :items="districts"
              item-text="district_name"
              item-value="id"
              outlined
              label="District"
              required
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="consultancy.location"
              label="Location"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="consultancy.phone_no"
              label="Phone Number"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider inset></v-divider>

        <h4>Registered Information</h4>
        <v-subheader>[<strong>Note</strong>: Registered Information of consultancy]</v-subheader>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="consultancy.registration_number"
              label="Registration Number"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            {{ consultancy.registration_date_np }}
            <nepali-date-picker
              v-model="consultancy.registration_date_np"
              label="नया‌ं मिती छान्ने विकल्प"
            ></nepali-date-picker>
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              v-model="consultancy.registration_date"
              label="Registered Date"
              outlined
              clearable
            ></v-text-field> -->
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="consultancy.registration_date"
                  label="Registration Date in A.D"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="consultancy.registration_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-file-input
              v-model="consultancy.renew_certificate"
              label="Select File Renew Certificate"
              outlined
              clearable
            ></v-file-input>
          </v-col>
          <v-col cols="6" :key="instantChange" v-if="isConsultancyRenewCertificateLoaded">
            <pdf-dialog
              :src="basePath + consultancy.renew_certificate"
              displayText="Full Screen"
            ></pdf-dialog>
            <br />
            <br />
            <inline-doc :src="basePath + consultancy.renew_certificate"></inline-doc>
            <br />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-checkbox
              v-model="agreeCheckbox"
              label="I agree to terms and conditions"
            ></v-checkbox>
            <v-btn
              class="mr-4"
              color="success"
              elevation="2"
              @click="updateConsultancyProfile(consultancy.id)"
              :disabled="!agreeCheckbox"
            >
              Update
            </v-btn>
            <v-btn color="error" :to="{ name: 'home' }" elevation="2" outlined> Cancel </v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col>
            <!-- <ConsultancyMember @consId="consultancyList($event)" /> -->
            <ConsultancyMember />
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
    </b-container>
  </div>
</template>

<script>
// import InfiniteLoading from "vue-infinite-loading";
// import Notification from "./navbar/components/Notification.vue";
import Navbar from "./../Navbar.vue"
import ConsultancyMember from "./ConsultancyMember.vue";
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";
export default {
  components: {
    ConsultancyMember,
    NepaliDatePicker,
    Navbar
  },
  props: {
    fileSize: {
      type: Number,
      default: 5,
    },
  },

  data: () => ({
    con_id: "",
    email: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    required(propertyType) {
      return (v) => !!v || `${propertyType} is required`;
    },
    requiredFormat(propertyType, regexexp) {
      var regex = new RegExp(regexexp, "g");
      return [
        (v) => !!v || `${propertyType} is reqiuired`,
        (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
      ];
    },
    requiredPhone(propertyType) {
      return [
        (v) => !!v || `${propertyType} is required`,
        (v) =>
          /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) || `${propertyType} is not valid phone number`,
      ];
    },
    districts: [],
    consultancy: {
      id: 0,
      name: "",
      name_np: "",
      email: "",
      district_id: "",
      location: "",
      registration_number: "",
      registration_date: "",
      renew_certificate: "",
    },
    agreeCheckbox: false,
    isConsultancyRenewCertificateLoaded: null,
    basePath: process.env.VUE_APP_BASE_URL + "img/renew_certificate/", // This one is for local propose to test the image
    // basePath: process.env.VUE_APP_BASE_URL,   // This one is for production
    successfulMessage: "",
    snackbar: {
      show: false,
      color: "success",
    },
    formData: new FormData(),
    fileSizeValidate() {
      return [
        (v) =>
          !v || v.size < this.fileSize * 1000000 || `File should be less than ${this.fileSize} MB`,
      ];
    },
    userRoleId: localStorage.getItem("auth_user_role_id"),
    itemsOld: [
      { title: "Dashboard", icon: "dashboard", link: "home" },
      { title: "Report", icon: "pie_chart", link: "report" },
      { title: "Analyse", icon: "pie_chart", link: "resultanalysis" },
      {
        title: "Application Status",
        icon: "folder",
        link: "applicationstatuspage",
      },
      { title: "Municipality", icon: "folder", link: "municipalitydetail" },
      { title: "Consultancy", icon: "folder", link: "consultancy" },
    ],
    user: [],
    municipality: [],
    messages: [
      {
        registration: {
          reg_id: 0,
          houseowner_name_np: "",
        },
        message: "",
        isRead: false,
        created_by: {
          username: "",
        },
      },
    ],
    munName: "",
    usernameInitials: "",
    userFullName: "",
    drawer: false,
    networkStatus: "Unable to connect to the internet!",
    // snackbar: true,
    internetNotConnected: false,
    timeout: -1,
    showMessage: false,
    showProfile: false,
    unreadMessageNo: 0,
    notificationMore: "",
    menu: "",
    instantChange: 0,
    menu2: false,
  }),
  computed: {
    items() {
      if (this.userRoleId == 8) {
        return [
          { title: "Dashboard", icon: "dashboard", link: "home" },
          {
            title: "Notice & Information",
            icon: "mdi-download",
            link: "referencefiles",
          },
        ];
      } else {
        return [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard-variant",
            link: "home",
          },
          { title: "Report", icon: "mdi-chart-arc", link: "report" },
          {
            title: "Analyse",
            icon: "mdi-home-analytics",
            link: "resultanalysis",
          },
          {
            title: "Application Status",
            icon: "mdi-list-status",
            link: "applicationstatuspage",
          },
          { title: "Downloads", icon: "mdi-download", link: "referencefiles" },
          {
            title: "Municipality",
            icon: "mdi-domain",
            link: "municipalitydetail",
          },
          {
            title: "Consultancy",
            icon: "mdi-account-group",
            link: "consultancy",
          },
        ];
      }
    },
  },
  created() {
    this.getDistricts();
    this.getConsultancyProfile();
    this.getConsultancId();
  },
  mounted() {
    //to check if the user has gone offline
    window.addEventListener("offline", this.updateOfflineStatus);
    window.addEventListener("online", this.updateOnlineStatus);
    // console.log(`My route:${this.$route.name}`); // to get current url path
    const currentuser_url = "api/profile";
    this.$axios
      .get(currentuser_url)
      .then((response) => {
        if (response.status === 200) {
          this.user = response.data;
          this.usernameInitials = `${
            this.user.first_name.charAt(0).toUpperCase() +
            this.user.last_name.charAt(0).toUpperCase()
          }`;
          this.userFullName = `${this.user.first_name} ${this.user.last_name}`;
          this.userRoleId = this.user.user_role_id;
        }
      })
      .catch((error) => {
        console.log("Navbar error");
        console.error(error);
        if (error.status !== 500) {
          window.localStorage.clear();
          this.$router.push("/login");
        }
      });

    const messages_url = "api/messages";
    this.$axios
      .get(messages_url)
      .then((response) => {
        if (response.status === 200) {
          this.messages = response.data.data;
        }
      })
      .catch((error) => {
        console.log("Navbar error");
        console.error(error);
      });

    const municipality_url = "api/municipality";
    this.$axios
      .get(municipality_url)
      .then((response) => {
        if (response.status === 200) {
          this.municipality = response.data.data;
          this.munName = response.data.data[0].name.split(" ")[0];
        }
      })
      .catch((error) => {
        console.log("Navbar error while retrieving municipality");
        console.error(error);
      });
  },
  methods: {
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      this.buildFormData(this.formData, data);
      return this.formData;
    },
    // getConsultancyData() {
    //   const conDataUrl = `c`;
    //   this.$axios
    //     .get(conDataUrl)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.consultancy = response.data.data[0];
    //         this.renew_certificate = this.consultancy.renew_certificate;
    //         console.log(`consultancydata: ${this.consultancy}`);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(`consultancy api error:${error}`);
    //     });
    // },
    getDistricts() {
      const districtUrl = "api/districts";
      this.$axios
        .get(districtUrl)
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getConsultancyProfile() {
      const conDataUrl = `api/consultancy-profile`;
      this.$axios
        .get(conDataUrl)
        .then((response) => {
          if (response.status === 200) {
            this.consultancy = response.data.data;
            this.isConsultancyRenewCertificateLoaded = this.consultancy.renew_certificate;
            this.instantChange += 1;
            // console.log(`this.renew_certificate: ${this.consultancy.renew_certificate}`);
          }
        })
        .catch((error) => {
          console.log(`consultancy api error:${error}`);
        });
    },
    updateConsultancyProfile(id) {
      const url = `api/consultancy-profile/${id}`;
      const self = this;
      this.formData = this.jsonToFormData(this.consultancy);
      self.formData.append("_method", "PUT");
      this.$axios
        .post(url, self.formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.consultancy = response.data.data;
            this.instantChange += 1;
            this.snackbar.show = true;
            this.successfulMessage = "Consultancy Profile Updated Successfully";
            this.getConsultancyProfile();
            this.getMemberData();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
    updateOnlineStatus() {
      console.log("Online aayo computer!!!");
      this.internetNotConnected = false;
    },
    updateOfflineStatus() {
      console.log("Oh no!! Off line gayo computer!!!");
      this.internetNotConnected = true;
    },
    refreshCurrentPage() {
      this.$root.$emit("home_search"); // Method 1
    },

    consultancyList() {
      this.getConsultancId();
    },
    getConsultancId() {
      const conIdUrl = `api/consultancy-profile`;
      this.$axios.get(conIdUrl).then((response) => {
        this.con_id = response.data.data['id'];
      });
    },
  },
};
</script>

<!-- <style scoped>
.navigation {
  position: relative;
  height: 15vh !important;
  width: 100vw;
}

.logout {
  width: 120px;
}

.reset-btn {
  width: 25px !important;
  height: 30px !important;
  margin-top: 10px !important;
  font-size: 12px !important;
}

.nav-top-logo {
  margin-bottom: 20px;
}

.container {
  display: flex;
}
</style>

-->
